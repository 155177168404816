import { Map as hashMap } from 'immutable';
import * as wasmCheck from 'wasm-check';

import config from '../../config';
import { isAiFeatureEnabled } from '../../helpers/ai/featureConfig';
import { getTreatment, isFeatureEnabled } from '../../helpers/featureFlags';
import { sendTagManagerEvent } from '../../helpers/sendTagManagerEvent';
import type { State } from '../../interfaces/State';

import { selectCourseId } from './course';
import { selectExerciseOrSubExType } from './exercises';

export const USER_STATUS = {
  LOGIN: 'login',
  UNAUTHORIZED: 'unauthorized',
  NOT_INITIATE: 'not_initiate',
  PENDING: 'pending',
  ERROR: 'error',
} as const;

export const selectUser = (state: State): any => state.get('user') || hashMap();
export const isUserFromDatacamp = (state: State): boolean => {
  const userGroup = selectUser(state).getIn(
    ['settings', 'current_group_name'],
    '',
  );
  return userGroup ? userGroup.includes('DataCamp') : false;
};
export const selectUserSettings = (state: State): any =>
  selectUser(state).get('settings') || hashMap();
export const selectUserFirstName = (state: State): any => {
  const userSettings = selectUserSettings(state);
  return userSettings.get('first_name');
};

export const selectIsUserLoggedIn = (state: State): boolean =>
  Boolean(selectUserSettings(state).get('id'));
export const selectIsUserNotLoggedIn = (state: State): boolean =>
  selectUser(state).get('status') === USER_STATUS.UNAUTHORIZED;
export const selectIsUserStatusError = (state: State): boolean =>
  selectUser(state).get('status') === USER_STATUS.ERROR;
export const selectMultiplexerUrl = (state: State): string =>
  selectUserSettings(state).get('multiplexerUrl');
export const selectUserIsInEnterpriseGroup = (state: State): boolean => {
  const userGroups = selectUserSettings(state).get('groups', []);
  const isActiveB2bGroup = (group: any): boolean =>
    group.get('type') === 'enterprise' && group.get('status') !== 'cancelled';
  return userGroups.some(isActiveB2bGroup);
};
export const selectUserLanguage = (state: State): string =>
  selectUserSettings(state).get('language', 'en-US');
export const selectUserIsEnabledForAiErrorExplanation = (
  state: State,
): boolean => {
  const aiAssistantEnabled = isFeatureEnabled('exp_wl_explain_error_button');
  const isAiAssistantEnabledForGroups = selectUserSettings(state).getIn(
    ['aiFlags', 'aiErrorExplanationEnabled'],
    false,
  );
  return aiAssistantEnabled && isAiAssistantEnabledForGroups;
};

export const selectUserIsEnabledForAiIncorrectSubmissions = (
  state: State,
): boolean => {
  const courseId = selectCourseId(state);
  const exerciseType = selectExerciseOrSubExType(state);
  const isB2BFlagEnabled = selectUserSettings(state).getIn(
    ['aiFlags', 'aiErrorExplanationEnabled'],
    false,
  );
  const isUserB2B = selectUserIsInEnterpriseGroup(state);
  const fromDatacamp = isUserFromDatacamp(state);
  return isAiFeatureEnabled({
    courseId,
    exerciseType,
    feature: 'aiHints',
    isB2BFlagEnabled,
    isUserB2B,
    isUserFromDatacamp: fromDatacamp,
  });
};

export const selectUserIsEnabledForAIExplainSolution = (
  state: State,
): boolean => {
  const isUserInEnterpriseGroup = selectUserIsInEnterpriseGroup(state);
  const fromDatacamp = isUserFromDatacamp(state);
  const b2c = !isUserInEnterpriseGroup;

  const b2b_enabled = selectUserSettings(state).getIn(
    ['aiFlags', 'aiSolutionExplanationEnabled'],
    false,
  );

  if (b2c || b2b_enabled || fromDatacamp) {
    const isSolutionExplanationEnabled = isFeatureEnabled(
      'cp--explain-solution',
    );
    return isSolutionExplanationEnabled;
  }
  return false;
};

export type TrackingGeneratorUser = {
  activeProducts?: string[];
  email?: string;
  id?: number;
  inEnterpriseGroup?: boolean;
  language?: string;
  loggedIn?: boolean;
  notLoggedIn?: boolean;
  phone?: string;
  statusError?: boolean;
};

export const selectTrackingGeneratorUser = (
  state: State,
): TrackingGeneratorUser => ({
  activeProducts: selectUserSettings(state).get('active_products'),
  email: selectUserSettings(state).get('email'),
  id: selectUserSettings(state).get('id'),
  inEnterpriseGroup: selectUserIsInEnterpriseGroup(state),
  language: selectUserSettings(state).get('language'),
  loggedIn:
    selectUser(state).get('status') == null
      ? undefined
      : selectIsUserLoggedIn(state),
  notLoggedIn:
    selectUser(state).get('status') == null
      ? undefined
      : selectIsUserNotLoggedIn(state),
  phone: selectUserSettings(state).get('phone'),
  statusError:
    selectUser(state).get('status') == null
      ? undefined
      : selectIsUserStatusError(state),
});

const INTRO_TO_PYTHON_COURSE_ID = 735;
const PYODIDE_ENABLED_COURSE_IDS = [INTRO_TO_PYTHON_COURSE_ID];

const wasmVersion = 1;
let browserSupportsWasm: boolean | null = null;
let browserSupportsBigInt64Array: boolean | null = null;
let pyodideCrashed: boolean | null = null;

const checkIfBrowserSupportsWasm = (userId: number): boolean => {
  if (browserSupportsWasm == null) {
    browserSupportsWasm = wasmCheck.support(wasmVersion);
    window.dataLayer.push({
      event: 'heap_event',
      heap_event_name: 'Learn - Campus - Wasm support',
      heap_event_properties: {
        app_id: config.appName,
        event_category: 'learning',
        is_supported: browserSupportsWasm,
        identity: userId,
      },
    });
  }

  return browserSupportsWasm;
};

const checkIfBrowserSupportsJSFeaturesUsedByPyodide = (
  userId: number,
): boolean => {
  if (browserSupportsBigInt64Array == null) {
    browserSupportsBigInt64Array = typeof BigInt64Array !== 'undefined';
    window.dataLayer.push({
      event: 'heap_event',
      heap_event_name: 'Learn - Campus - BigInt64Array support',
      heap_event_properties: {
        app_id: config.appName,
        event_category: 'learning',
        is_supported: browserSupportsBigInt64Array,
        identity: userId,
      },
    });
  }

  return browserSupportsBigInt64Array;
};

const checkIfBrowserRunsPyodideWithoutCrashing = (userId: number): boolean => {
  if (pyodideCrashed == null) {
    pyodideCrashed = localStorage.getItem('supportsPyodide') === 'false';
    window.dataLayer.push({
      event: 'heap_event',
      heap_event_name: 'Learn - Campus - Pyodide crashes',
      heap_event_properties: {
        app_id: config.appName,
        event_category: 'learning',
        pyodide_crashes: pyodideCrashed,
        identity: userId,
      },
    });
  }

  return !pyodideCrashed;
};

const selectCourseSupportsWasm = (state: State): boolean => {
  const courseId = selectCourseId(state);
  const userId = selectUserSettings(state).get('id');
  return (
    PYODIDE_ENABLED_COURSE_IDS.includes(courseId) &&
    checkIfBrowserSupportsWasm(userId) &&
    checkIfBrowserSupportsJSFeaturesUsedByPyodide(userId)
  );
};

export const selectIsDualCodeExecutionBackendWithWasmEnabled = (
  state: State,
): boolean => {
  // If the browser doesn't support WASM, the user shouldn't be part of the experiment
  if (!selectCourseSupportsWasm(state)) {
    return false;
  }

  const treatment = getTreatment('cp--dual-code-execution-backend-with-wasm');

  // If the user is not in any group, they shouldn't be part of the experiment
  if (treatment !== 'on' && treatment !== 'off') {
    return false;
  }

  // Track the group the user is in the experiment (on=WASM, off=Mux)
  sendTagManagerEvent({
    bucket: treatment,
    event: 'bucket_experiment',
    experiment_name: 'cp--dual-code-execution-backend-with-wasm',
  });

  // If the user is in the off group, they should get Mux
  if (treatment === 'off') {
    return false;
  }

  // If the user is in the on group, they should get WASM.
  // If WASM crashed, they should get Mux but still be part of the on group.
  const userId = selectUserSettings(state).get('id');
  return checkIfBrowserRunsPyodideWithoutCrashing(userId);
};

export const selectIsDualCodeExecutionBackendEnabled = (
  state: State,
): boolean => {
  const userId = selectUserSettings(state).get('id');
  return (
    selectCourseSupportsWasm(state) &&
    checkIfBrowserRunsPyodideWithoutCrashing(userId) &&
    isFeatureEnabled('cp--dual-code-execution-backend')
  );
};

export const selectIsPyodideBackendEnabled = (state: State): boolean => {
  const userId = selectUserSettings(state).get('id');
  return (
    selectCourseSupportsWasm(state) &&
    checkIfBrowserRunsPyodideWithoutCrashing(userId) &&
    isFeatureEnabled('cp--execute-code-in-wasm')
  );
};

export function selectHasSeenCampusTour(state: State): boolean {
  return selectUserSettings(state).get('has_seen_campus_tour') ?? false;
}
