import isEqual from 'lodash/isEqual';
import raven from 'raven-js';
import { timeout } from 'rxjs/operators';

import { ActiveCourseImagesResponse } from '../../../helpers/api/activeCourseImagesResponse';
import { imbApi } from '../../../helpers/api/clients';
import ApiClient from '../../../helpers/ApiClient';
import { getImbClient } from '../../../helpers/getClients';
import type { PreFetchedDataState } from '../../reducers/preFetchedData';
import { REQUEST_STATUS } from '../../selectors';

const compareWithNewApiEndpoint = (
  courseId: string,
  oldApiResult: ActiveCourseImagesResponse,
) => {
  imbApi
    .getCourseImages(courseId)
    .then((newApiResult) => {
      if (!isEqual(oldApiResult, newApiResult)) {
        raven.captureMessage(
          `Course images API responses don't match for course ${courseId}`,
          {
            level: 'warning',
            extra: {
              oldApiResult,
              newApiResult,
            },
          },
        );
      }
    })
    .catch((error) => {
      raven.captureException(
        `Error calling new IMB API endpoint for course ${courseId}`,
        {
          extra: { error: JSON.stringify(error) },
        },
      );
    });
};

export const getCourseImagesAsPromise = async ({
  authorizationHeader,
  courseAsPromise,
  preFetchedData,
}: {
  authorizationHeader: string | undefined;
  courseAsPromise: Promise<any>;
  preFetchedData: PreFetchedDataState;
}): Promise<ActiveCourseImagesResponse> => {
  const preFetchedDataRequest = preFetchedData.get('courseImages');
  switch (preFetchedDataRequest.get('status')) {
    case REQUEST_STATUS.SUCCESS:
      return preFetchedDataRequest.get('data') as ActiveCourseImagesResponse;
    case REQUEST_STATUS.FAILED:
      return Promise.reject(preFetchedDataRequest.get('data'));
    default: {
      const course = await courseAsPromise;
      const courseImages = await getImbClient()
        .execute(
          ApiClient.imbEndpoints.getCourseImages({
            courseId: course.id,
            authorizationHeader,
          }),
        )
        .pipe(timeout(5000))
        .toPromise()
        .then(({ body }: { body: unknown }) => body);

      const parsedResult = ActiveCourseImagesResponse.parse(courseImages);

      // Run the new API call in parallel without waiting for it
      compareWithNewApiEndpoint(course.id, parsedResult);

      return parsedResult;
    }
  }
};
