import type { History } from 'history';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import type { Store } from 'redux';
import type { ActionsObservable } from 'redux-observable';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/universal-rx-request` if i... Remove this comment to see the full error message
// eslint-disable-next-line no-restricted-imports
import rxRequest from 'universal-rx-request';

import ApiClient from '../../../helpers/ApiClient';
import {
  getCampusApiClient,
  getTeachClient,
} from '../../../helpers/getClients';
import { isTeachPreview } from '../../../helpers/isTeachPreview';
import { getSplittedPathName } from '../../../helpers/navigation';
import type { State } from '../../../interfaces/State';
import type { Action } from '../../actions';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { getChapterProgress } from '../exercises';

const campusApiClient = getCampusApiClient();
const teachClient = getTeachClient();
const REQUEST_STATUS = rxRequest.STATUS;

export const epicGetExercisesFromChapter = (
  action$: ActionsObservable<Action>,
  store: Store<State, Action>,
  history: History,
) =>
  action$.ofType(actions.EPIC_GET_EXERCISES_FROM_CHAPTER).mergeMap((action) =>
    Rx.Observable.forkJoin(
      getChapterProgress(action, actions.UPDATE_CHAPTER_PROGRESS),
      (isTeachPreview(history.location.pathname)
        ? teachClient
        : campusApiClient
      )
        .execute(
          ApiClient.endpoint.getExercises(action.courseRef, action.chapterRef, {
            language: selectors.selectPathLanguage(store.getState()),
            authorizationHeader: undefined,
          }),
          action,
        )
        .filterOnSucceedRequest()
        .map((response: any) =>
          actions.updateExercisesInChapter({
            chapterRef: action.chapterRef,
            exercises: response.data.body,
          }),
        )
        .catch((error: any) => Rx.Observable.of(error)),
    ).map((results) => {
      const progressAction = results[0] as { data: unknown };
      const chapterAction = results[1] as any;
      return {
        ...chapterAction,
        progress: isEmpty(progressAction.data) ? [] : progressAction.data,
      };
    }),
  );

export const epicUpdateCourseProgress = (
  action$: ActionsObservable<Action>,
  _store: Store<State, Action>,
  history: History,
) =>
  action$
    .ofType(actions.EPIC_UPDATE_EXERCISES)
    .distinctUntilChanged(isEqual)
    .switchMap((action) =>
      (isTeachPreview(history.location.pathname)
        ? teachClient
        : campusApiClient
      )
        .execute(ApiClient.endpoint.getCourseProgress(action), action)
        .filter(
          (response: any) => response.requestStatus === REQUEST_STATUS.SUCCESS,
        )
        .map((response: any) =>
          actions.updateCourseProgress({ data: response.data.body }),
        )
        .catch(() =>
          Rx.Observable.of(actions.updateCourseProgress({ data: {} })),
        ),
    );

export const middlewareResetCourseProgress =
  () => (next: any) => (action: any) => {
    if (action.type === actions.MID_RESET_COURSE_PROGRESS) {
      const noop = () => {};
      campusApiClient
        .execute(ApiClient.endpoint.resetProgress(action))
        .do(noop, noop, () => {
          window.location = action.redirection;
        })
        .subscribe(noop);
    }
    return next(action);
  };

export const middlewareUpdateCourse =
  (store: any) => (next: any) => (action: any) => {
    if (action.type === actions.UPDATE_MID_LOCATION) {
      const state = store.getState();
      const chapter = selectors.selectChapter(state);
      const { dispatch } = store;
      const { chapterRef, courseRef } = getSplittedPathName(action.pathname);
      const index = Number(action.query.ex) - 1;

      /* always true because of the teach app (course is a key that doesn't specially match with the course slug) */
      let isSameCourse =
        courseRef === state.getIn(['course', 'slug']) ||
        Number(courseRef) === state.getIn(['course', 'id']);
      isSameCourse = true;

      const isSameChapter =
        chapterRef === chapter.get('slug') ||
        Number(chapterRef) === chapter.get('id');
      const isSameExIndex = index === state.getIn(['exercises', 'current']);
      if (isSameCourse && isSameChapter && !isSameExIndex) {
        return dispatch(actions.updateCurrentExercise({ index }));
      }
      if (isSameCourse && !isSameChapter) {
        dispatch(actions.updateCurrentExercise({ index: null }));
        return dispatch(
          actions.epicUpdateExercises({
            chapterRef,
            courseRef,
            chapters: state.getIn(['course', 'chapters']),
            exIndex: index,
            injection: { language: selectors.selectLanguage(state) },
          }),
        );
      }
      if (!isSameCourse) {
        return console.error('should never happens'); // eslint-disable-line no-console
      }
      return next(action);
    }
    return next(action);
  };
