import inRange from 'lodash/inRange';
import raven from 'raven-js';

import ApiClient, { LearningRecapResponse } from '../../../helpers/ApiClient';
import type { Exercise } from '../../../helpers/fetchExternalExerciseIds';
import { formatDistanceLocalized } from '../../../helpers/formatDistanceLocalized';
import { getCampusApiClient } from '../../../helpers/getClients';
import { getLanguage } from '../../../i18n';
import { REQUEST_STATUS } from '../../selectors';

export type LearningRecap = {
  chapterNumber: number;
  chapterTitle: string;
  courseTitle: string;
  derivedCourseContentId: number;
  lastActive: string;
  lastActiveDate: Date;
  nextLessonGoal: string;
  summary: string;
};

export const getLearningRecapAsPromise = async ({
  authorizationHeader,
  courseAsPromise,
  exerciseNumber,
  exercisesAsPromise,
  isPreview,
  language,
  preFetchedData,
}: {
  authorizationHeader: string | undefined;
  courseAsPromise: Promise<{ id: number; title: string }>;
  exerciseNumber: number;
  exercisesAsPromise: Promise<Exercise[]>;
  isPreview: boolean;
  language: string;
  preFetchedData: any;
}): Promise<LearningRecap | null> => {
  const preFetchedDataRequest = preFetchedData.get('learningRecap');
  switch (preFetchedDataRequest.get('status')) {
    case REQUEST_STATUS.SUCCESS:
      return Promise.resolve(preFetchedDataRequest.get('data'));
    case REQUEST_STATUS.FAILED:
      return Promise.reject(preFetchedDataRequest.get('data'));
    default: {
      const course = await courseAsPromise;
      const client = getCampusApiClient();

      if (isPreview) {
        return Promise.resolve(null);
      }

      const exercises = await exercisesAsPromise;
      let nextExerciseNumber: number = exerciseNumber;
      if (!inRange(exerciseNumber - 1, exercises.length)) {
        nextExerciseNumber = 1;
      }

      const nextExerciseId = exercises.find(
        (ex) => ex.number === nextExerciseNumber,
      )?.id;

      if (nextExerciseId === undefined) {
        return Promise.resolve(null);
      }

      return client
        .execute(
          ApiClient.endpoint.getCourseLearningRecap({
            authorizationHeader,
            courseId: course.id,
            nextExerciseId,
            language: getLanguage(language),
          }),
        )
        .toPromise()
        .then(({ body }: { body: unknown }): LearningRecap | null => {
          const data = LearningRecapResponse.parse(body);
          if (data == null) {
            return null;
          }

          const now = new Date();
          const lastActiveDate = new Date(data.lastActiveDate);
          const lastActive = formatDistanceLocalized(
            lastActiveDate,
            now,
            language,
          );

          return {
            summary: data.summary,
            nextLessonGoal: data.nextLessonGoal,
            derivedCourseContentId: data.derivedCourseContentId,
            lastActive,
            chapterNumber: data.lastCompletedLesson.chapterNumber,
            chapterTitle: data.lastCompletedLesson.chapterTitle,
            courseTitle: course.title,
            lastActiveDate,
          };
        })
        .catch((error: any) => {
          const status = error?.error?.status ?? 0;
          const isError = status >= 500;

          if (isError) {
            raven.captureMessage(
              `Error handling learning recap ${error?.error?.message ?? ''}`,
              {
                extra: {
                  error,
                  course_id: course.id,
                  next_exercise_id: nextExerciseId,
                  status,
                },
              },
            );
          }

          return Promise.resolve(null);
        });
    }
  }
};
